<template>
  <!-- 点滴记录 -->
  <div class="dailyRecord">
    <!-- 表单 -->
    <van-form @submit="onSubmit">
      <van-field
        v-model="form.content"
        rows="3"
        type="textarea"
        placeholder="请输入内容"
        :rules="[{ required: true, message: '内容不能为空' }]"
        clearable
      />
      <van-field name="uploadList" label="照片" class="require">
        <template #input>
          <div>
            <van-uploader
              v-model="form.uploadList"
              @delete="deleteImg"
              :show-upload="false"
              accept="*"
            >
            </van-uploader>
            <span class="imgUpload" @click="chooseImage">+</span>
          </div>
        </template>
      </van-field>
      <van-field name="videoList" label="视频(不超过3分钟)" class="require">
        <template #input>
          <van-uploader accept=".mp4,.mov,.3gp" :before-read="beforeRead" />
        </template>
      </van-field>
      <div style="text-align:center">
        <video
          controls
          playsinline
          webkit-playsinline
          x-webkit-airplay="allow"
          preload="metadata"
          style="height:4.2rem;width:6.2rem;"
          loop
          class="video"
          :src="item.uploadUrl"
          :poster="item.url + '?x-oss-process=video/snapshot,t_1,m_fast'"
          v-for="(item, index) in form.videoList"
          :key="index"
        ></video>
      </div>
      <van-field
        readonly
        clickable
        name="picker"
        :value="form.circleType"
        label="标签"
        placeholder="请点击选择标签"
        @click="showPicker = true"
        left-icon="label-o"
        right-icon="arrow"
        input-align="right"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>

      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          :loading="loading"
          color="#47AFA7"
          >保存</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Form, Field, Button, Uploader, Popup, Picker, Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
  name: "DailyRecord",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Toast.name]: Toast
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
      form: {
        content: "",
        uploadList: [], //上传图片
        videoList: [],
        circleType: "日常动态" //默认日常动态
      },
      showPicker: false,
      columns: [],
      loading: false,
      localIdImgs: []
    };
  },
  mounted() {
    //根据类型不同赋值表标签
    this.columns =
      this.userType == 3
        ? ["日常动态", "亲子任务", "宝贝爱运动"]
        : ["日常动态", "主题课程", "节庆活动"];
    // 底部页面跳转自动赋值
    if (this.$route.query.name) {
      this.form.circleType = this.$route.query.name;
    }
    this.getWechatSdk();
  },
  methods: {
    //提交
    onSubmit() {
      // 类型转换
      let circleType = this.columns.indexOf(this.form.circleType);
      this.loading = true;
      this.$axios
        .post(
          this.userType == 3
            ? "/api/ChildrenCircle/CreateChildrenCircle"
            : "/api/ClassCircle/CreateClassCircle",
          this.userType == 3
            ? {
                Content: this.form.content,
                AccessoryPostList: this.form.uploadList.concat(
                  this.form.videoList
                ),
                ChildrenCircleType: circleType + 1
              }
            : {
                ClassCircleType: circleType + 1,
                Content: this.form.content,
                AccessoryPostList: this.form.uploadList.concat(
                  this.form.videoList
                )
              }
        )
        .then(res => {
          if (res.code == 200) {
            this.loading = false;
            this.$toast.success(res.msg);
            this.$router.back();
          } else {
            this.loading = false;
            this.$toast.fail(res.msg || "操作失败");
          }
        })
        .catch(e => {
          this.loading = false;
          this.$toast.fail(e.msg || "操作失败");
        });
    },
    onConfirm(value) {
      this.form.circleType = value;
      this.showPicker = false;
      //console.log(this.columns.indexOf(value))
    },
    // 预览
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    // 判断上传文件是否为数组
    isArray(file) {
      //if(file instanceof Array){
      file.forEach(item => {
        // 判断是否需要压缩(压缩走压缩上传)
        if (item.size / 1024 > 1025 && item.type.indexOf("video") == -1) {
          this.isCompress(item);
        } else {
          // 不需要压缩,正常图片上传的
          this.uploadImg(item);
        }
      });
      //}
    },
    // 是否需要压缩
    isCompress(file) {
      //if (file.size / 1024 > 1025) {
      this.$compress.photoCompress(
        file,
        {
          quality: 0.2
        },
        base64Codes => {
          file = this.$compress.dataURLtoFile(base64Codes, file.name);
          this.uploadImg(file);
        }
      );
      //}
    },
    beforeRead(file) {
      // 判断文件是否为数组
      if (file instanceof Array) {
        this.isArray(file);
      } else {
        // 非数组,单个文件上传
        this.uploadSingle(file);
      }
    },
    // 单文件图片上传
    async uploadSingle(file) {
      if (file.size / 1024 > 1025 && file.type.indexOf("video") == -1) {
        this.isCompress(file);
      } else {
        this.uploadImg(file);
        // const videoUrl = URL.createObjectURL(file);
        // // const videoElement = document.createElement("video");
        // // videoElement.src = videoUrl;
        // var audioElement = new Audio(videoUrl);
        // console.log(audioElement)
        // var duration;
        // audioElement.addEventListener("loadedmetadata", function() {
        //   duration = parseInt(audioElement.duration); //时长为秒，取整
        //   console.log(duration);
        // });

        // const duration = Math.ceil(videoElement.duration);
        // // 释放 videoUrl
        // URL.revokeObjectURL(videoUrl);
        // if (duration > 180) {
        //   this.$toast.fail("视频时长最多只能3分钟");
        // } else {
        //   // 不需要压缩,正常上传的
        //   this.uploadImg(file);
        // }
      }
    },
    uploadImg(file) {
      //alert("进来了");
      let that = this;
      Toast.loading({
        message: "上传中...",
        duration: 0
      });
      let formData = new FormData();
      formData.append("LastFile", file);
      formData.append("FileName", file.name);

      that.$axios.upload("/api/File/UploadFile", formData).then(res => {
        Toast.clear();
        if (res.code === 200) {
          //console.log(res)
          that.$toast.success(res.msg);
          if (
            res.data.osskey.substring(res.data.osskey.lastIndexOf(".")) ==
              ".mp4" ||
            res.data.osskey.substring(res.data.osskey.lastIndexOf(".")) ==
              ".mov" ||
            res.data.osskey.substring(res.data.osskey.lastIndexOf(".")) ==
              ".3gp"
          ) {
            that.form.videoList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url
            });
          } else {
            that.form.uploadList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url
            });
          }
        } else {
          that.$toast.fail(res.msg || "操作失败");
          return false;
        }
      });
    },
    deleteImg() {},
    getWechatSdk() {
      let wechaturl = window.location.href.split("#")[0];
      // alert(window.localStorage.getItem('wechaturl'))
      // if (window.localStorage.getItem('wechaturl') !== undefined) {
      //   wechaturl = window.localStorage.getItem('wechaturl').split('#')[0];
      // }
      this.$axios
        .get("/api/WeChat/WeChatAuthorization", {
          url: wechaturl
        })
        .then(res => {
          if (res.code === 200) {
            this.wxConfig(res.data[0]);
          } else {
          }
        });
    },
    wxConfig(result) {
      let _this = this;
      let timestamp = result.timeStamp;
      let noncestr = result.nonceStr;
      let signature = result.signature;
      let appId = result.appId;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: noncestr, // 必填，生成签名的随机串
        signature: signature, // 必填，签名，见附录1
        jsApiList: [
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "onMenuShareQQ",
          "previewImage",
          "openLocation",
          "getLocation",
          "chooseImage",
          "uploadImage",
          "downloadImage"
        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      wx.ready(function() {
        //setSessionStorage("wxready", true);
        console.log("微信已加载完毕！");
      });
      wx.error(function(res) {
        // alert(res)
        console.log(res);
      });
    },
    chooseImage(index, type) {
      let _this = this;
      wx.chooseImage({
        count: 9, // 默认9
        sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
        success: function(res) {
          let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          _this.wxImgData(localIds, type);
        }
      });
    },
    wxImgData(localIds, type) {
      let _this = this;
      _this.localIdImgs = [];
      var i = 0;
      var length = localIds.length;
      var upload = function() {
        wx.getLocalImgData({
          localId: localIds[i], // 图片的localID
          success: function(res) {
            Toast.loading({
              message: "上传中...",
              duration: 0
            });
            let localData = res.localData; // localData是图片的base64数据，可以用img标签显示
            if (localData.indexOf("data:image") != 0) {
              //判断是否有这样的头部
              localData = "data:image/jpeg;base64," + localData;
            }
            localData = localData
              .replace(/\r|\n/g, "")
              .replace("data:image/jgp", "data:image/jpeg"); // 此处的localData 就是你所需要的base64位
            _this.localIdImgs.push(localData);
            if (_this.localIdImgs.length >= length) {
              //alert("_this.localIdImgs.length+" + _this.localIdImgs.length);
              _this.getBase(_this.localIdImgs); //这里就可以拿到所有的base64传给逻辑函数了
            }
            i++; //因为多图片上传采用了这个方法
            i < length && upload();
            // let file = _this.dataURLtoFile(localData);
            // // params.append("request", file);
            // //接口上传
            // alert(file);
            // _this.uploadImg(file);
          }
        });
      };
      upload();
    },
    getBase(base) {
      let _this = this;
      const photoName = `${new Date().getTime()}`;
      base.forEach(element => {
        Toast.loading({
          message: "上传中...",
          duration: 0
        });
        let initData = element.replace(/%0A|\s/g, "");
        //这里的base64ToFile就是base64转文件流的方法
        let p = _this.dataURLtoFile(initData, `${photoName}.jpg`);
        _this.uploadImg(p);
      });
    },
    dataURLtoFile(dataurl, filename = "weixin.jpg") {
      var arr = dataurl.split(","),
        //mime = arr[0].match(/:(.*?);/)[1],
        mime = "image/jpeg",
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
  }
};
</script>

<style lang="less" scoped>
.dailyRecord {
  padding: 10px;
}
.imgUpload {
  font-size: 20px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  width: 2.133333rem;
  height: 2.133333rem;
  margin: 0 0.213333rem 0.213333rem 0;
  background-color: #f7f8fa;
}
</style>
